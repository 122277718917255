import React from "react";
import { lineHeight, SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import { TbPointFilled } from "react-icons/tb";
import { MdMicNone } from 'react-icons/md';
import { SiFaceit } from "react-icons/si";
import { LuShieldCheck } from "react-icons/lu";
import { BsSteam } from "react-icons/bs";
import SteamIcon from '../../../assets/lobbies/steam-icon.png';
import { Image } from "@mui/icons-material";

const Player = ({ sx = {}, selected = false, onClick }: { sx?: SxProps<Theme>, selected?: boolean, onClick?: () => void }) => {
	return (
		<Box>
			<Button
				onClick={onClick}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'start',
					gap: '16px',
					padding: '10px',
					borderRadius: '5px',
					backgroundColor: '#3F4355',
					border: `2px solid ${selected ? '#5595F9' : 'transparent'}`,
					...sx
				}} fullWidth>
				<Avatar sx={{ width: '48px', height: '48px' }} src="https://picsum.photos/200" />
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px', color: 'white' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ fontSize: '15px', lineHeight: '15px' }}>crisbyyy</Typography>
						<TbPointFilled color="#FF9F46" />
						<MdMicNone size={16} color="#5D6170" />
						<LuShieldCheck size={14} />
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
						<SiFaceit size={13} color="#e4621b" />
						<Typography sx={{ fontSize: '10px' }}>3222 ELO</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
						<img src={SteamIcon} width={11} height={11} />
						<Typography sx={{ fontSize: '10px' }}>17890</Typography>
					</Box>
				</Box>
			</Button>
		</Box>
	)
}

export default Player;