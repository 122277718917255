import React from 'react';
import axios from 'axios';
import { handleError } from '../../components/snackbar/reducer';
import { useDispatch } from 'react-redux';
import { useProUser } from '../billing/utils';

export type GameModeMap = {
    Name: string;
    Image: string;
    VipOnly: boolean;
};

export function useGameModeMaps(mode: string): { mapsLoaded: boolean; maps: GameModeMap[] } {
    const dispatch = useDispatch();
    const [mapsLoaded, setLoaded] = React.useState(false);
    const [maps, setMaps] = React.useState([]);

    React.useEffect(() => {
        async function load() {
            try {
                const res = await axios.get(`/api/play/modes/${mode}/maps`);
                setMaps(res.data);
                setLoaded(true);
            } catch (e) {
                handleError(e)(dispatch);
            }
        }

        load();
    }, [mode]);

    return { mapsLoaded, maps };
}
