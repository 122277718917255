import React from "react";
import styled from "styled-components";
import PlayAdsImg1 from '../../assets/prac_com_ad_vert.jpg';
import PlayAdsImg2 from '../../assets/prac_com_ad_vert_2.jpg';

const AdImageContainer = styled.div`
    flex: 1 1;
    padding: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
`;

const AdImage = styled.img`
    max-width: 100%;
    cursor: pointer;
    @media (max-width: 1900px) {
        display: none;
    }
`;

const CS2Ad = () => {
	const link = "https://go.pracc.com/faceit";
	const openLink = () => window.open(link);

	return (
		<AdImageContainer>
			<AdImage onClick={openLink} src={PlayAdsImg2} />
		</AdImageContainer>
	)
}

export default CS2Ad;